import React, { Fragment, useEffect, useState } from 'react';
import { Route, Redirect, Switch, useParams } from 'react-router-dom';
import { VerticalLinkGroup, Breadcrumbs } from 'components/navigation';
import { JobDetails } from 'pages/job/edit/job-details';
import { JobNotes } from 'pages/job/edit/notes';
import { JobChangelog } from 'pages/job/edit/changelog';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { PageRestricted } from 'pages/errors/page-restricted';
import { JobService, LocationService, OperatingHoursService } from 'services';
import { NotificationToaster } from "components/notifications";
import { Intent } from '@blueprintjs/core';
import { LocationDetails } from './location';
import { ListingTable } from "components/listing";
import axios from 'axios';
import moment from "moment";
import { CloseJob } from './close-job';

var _ = require('underscore');

export function Job(props) {
    const timeFormat = "YYYY-MM-DD HH:mm:ss";
    const inductionDateTime = "01 Jan 2000";

    const addressModel = {
        locationId: 0,
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        postTown: "",
        county: "",
        postCode: "",
        phoneNumber: ""
    };

    const additionalInfoModel = {
        locationId: null,
        weighPadAvailable: null,
        inductionRequired: false,
        inductionDurationTime: null,
        inductionTime: 0
    };

    const { jobId } = useParams();
    const [additionalInfo, setAdditionalInfo] = useState(additionalInfoModel);
    const requiredAction = ["ScheduledAppointment:View", "Internal"];
    const [loadingData, setLoadingData] = useState(true);
    const canAccessPage = UserHasAccess(requiredAction);
    const isInternal = UserHasAccess("Internal");
    const [jobDetails, setJobDetails] = useState({});
    const [address, setAddress] = useState(addressModel);
    const [jobNote, setJobNote] = useState('');
    const [additionalPropertiesMapped, setAdditionalPropertiesMapped] = useState(false);
    const [operatingHours, setOperatingHours] = useState([]);
    const contactTableHeaders = ["Email Address", "First Name", "Last Name", "Phone Number", "Business Area"];
    const [contactTableRows, setContactTableRows] = useState([]);
    const [contactRecordCount, setContactRecordCount] = useState(0);   
    const noDataMessage = "You have no contacts.";
    const [hasTachoContact, setHasTachoContact] = useState(false);

    var routes = [
        { location: `/job${props.baseUrl}/${jobId}/job-details`, title: "Job Details" },
        { location: `/job${props.baseUrl}/${jobId}/location-details`, title: "Visit Location Details"},
        { location: `/job${props.baseUrl}/${jobId}/contacts`, title: "Contacts" }
    ];

    if (isInternal) {
        routes.push({ location: `/job${props.baseUrl}/${jobId}/job-notes`, title: "Internal Notes", selected: false });
        routes.push({ location: `/job${props.baseUrl}/${jobId}/changes`, title: "History" });
    }

    useEffect(() => {
        if (!canAccessPage) {
            return;
        }
        setLoadingData(true);
        var failed = false;
        JobService.getJob(jobId).then(function (jobResponse) {
            
            setJobDetails(jobResponse);
            setAddress({
                ...address,
                locationId: jobResponse.locationId
            });

            if (jobResponse.locationId === 0) {
                setLoadingData(false);
                return;
            }

            axios.all([LocationService.getLocationAdditionalInformation(jobResponse.locationId),
                LocationService.getContacts(jobResponse.locationId)
            ])
                .then(axios.spread(function (additionalInfoResponse, contactResonse) {

                    if (additionalInfoResponse.address) {
                        setAddress(additionalInfoResponse.address);
                    }

                    var locationBusinessArea = additionalInfoResponse.businessAreaAdditionalDetails.find(e => e.locationBusinessAreaId === jobResponse.jobProductBusinessAreaId);

                    if (locationBusinessArea !== undefined) {
                        setOperatingHours(OperatingHoursService.getFullWeekOperatingHours(locationBusinessArea.operatingHours, additionalPropertiesMapped, setAdditionalPropertiesMapped));
                        setJobNote(locationBusinessArea.notes);
                    }

                    var hours = 0;
                    var mins = 0;
                    if (additionalInfoResponse.additionalInfo.inductionTime !== undefined && additionalInfoResponse.additionalInfo.inductionTime > 0) {
                        hours = Math.floor(additionalInfoResponse.additionalInfo.inductionTime / 60);
                        mins = additionalInfoResponse.additionalInfo.inductionTime - (hours * 60);
                    }

                    var cloneAdditionalInfo = {
                        ...additionalInfoResponse.additionalInfo,
                        inductionDurationTime: moment(inductionDateTime).startOf('day').set('hour', hours).set('minute', mins).format(timeFormat),
                        locationId: jobResponse.locationId,
                        inductionTime: additionalInfoResponse.additionalInfo.inductionTime == null ? 0 : additionalInfoResponse.additionalInfo.inductionTime
                    };
                    
                    setAdditionalInfo(cloneAdditionalInfo);
                    setContactsRows(contactResonse);
                    setContactRecordCount(contactResonse.length)
                    setLoadingData(false);
                    setHasTachoContact(hasContactOfTypeTacho(contactResonse));
                }), (error) => {
                    failed = true;
                });

            setLoadingData(false);
        }, () => {
            setLoadingData(false);
            failed = true;
        });

        if (failed) {
            NotificationToaster.show(Intent.DANGER, "Could not get the address information. Please refresh the page.");
        }
    }, [jobId]);

    function onSave(modifiedJob) {
        setJobDetails(modifiedJob);
    }

    function onCloseJobSave(modifiedJob) {
        setJobDetails(modifiedJob);
    }

    function hasContactOfTypeTacho(contactResponse) {
        return (contactResponse.length > 0 && contactResponse.find(e => e.contactType === "Tacho") !== undefined);
    }

    function setContactsRows(contacts) {
        let data = [];

        if (contacts.length > 0) {
            data = contacts.map((d) => {

                return [d.emailAddress, d.firstName,d.lastName, d.phoneNumber, d.contactType];
            })
        }

        setContactTableRows(data);
    }
    return (


        <UserAccess perform={requiredAction}
            yes={() => (
                <Fragment>
                    <div className="row">
                        <Breadcrumbs items={props.breadcrumbs} />
                        {<h1>{"Reference " + jobId}</h1>}
                        <div className="intro-text">
                            <p>Here you can view and configure your job details.</p>
                        </div>
                    </div>

                    <div className="layout-cols">
                        <div className="col side-col">
                            <div className="row">
                                <VerticalLinkGroup links={routes}></VerticalLinkGroup>
                            </div>
                        </div>
                        <div className="col content-col">
                            <Switch>                                
                                <Route path={routes[0].location}>
                                    <JobDetails job={jobDetails} hasTachoContact={hasTachoContact} onSave={onSave} id={jobId}></JobDetails>
                                </Route>
                                <Redirect exact from="/job/:id(\d+)" to="/job/:id(\d+)/job-details" />
                                <Redirect exact from="/job/scheduling-view/:id(\d+)" to="/job/scheduling-view/:id(\d+)/job-details" />

                                <Route path={routes[1].location}>
                                    <LocationDetails addressSettings={{ address: address }} loading={loadingData} operatingHours={operatingHours} additionalInfo={{ additionalInfo }} id={jobId}></LocationDetails>
                                </Route>
                                <Route path={routes[2].location}>
                                    <div className="row">
                                        <h2>Contact Details</h2>
                                        <div className="spacer-bottom">
                                            <ListingTable
                                                id="listing-table-contacts"
                                                headers={contactTableHeaders}
                                                data={contactTableRows}
                                                loadingData={loadingData}
                                                noDataMessage={noDataMessage}
                                                pageable={false}
                                                totalRecordCount={contactRecordCount}
                                            />
                                        </div>
                                    </div>

                                </Route>
                                <Route path={routes[3].location}>
                                    <JobNotes job={jobDetails} locationNotes={jobNote} onSave={onSave}></JobNotes>
                                </Route>
                                <Route path={routes[4].location} >
                                    <JobChangelog jobId={jobId}></JobChangelog>
                                </Route>
                                <Route path={`/job/${jobId}/close-job`} >
                                    <CloseJob job={jobDetails} onSave={onCloseJobSave} id={jobId}></CloseJob>
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </Fragment>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );

}