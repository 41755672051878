import ApiService from 'services/ApiService';
import ApiRequestService from "services/ApiRequestService";

export const EmployeeService = {
	getPolicy(policyId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/employee/Policies/${policyId}`).then(
				(result) => {
					const policy = result.data;
					policy.locations = policy.locationIds.map((location, index) => {
						return {
							id: location.id,
							name: policy.locationNames[index]
						};
					});

					resolve(policy);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getPolicies(resultCount, currentPageNumber, sortField, sortDir) {		
		return new Promise((resolve, reject) => {
			ApiService.get(`/employee/Policies?resultCount=${resultCount}&currentPageNumber=${currentPageNumber}&sortField=${encodeURI(sortField)}&sortDirection=${sortDir}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getEmployeeOptions() {
		return new Promise((resolve, reject) => {
			ApiService.get(`/employee/Options/Employee`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getDeclarations() {
		return new Promise((resolve, reject) => {
			ApiService.get(`/employee/PolicyDocuments`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	savePolicy(policy) {
		policy.locationIds = policy.locations;

		return new Promise((resolve, reject) => {
			ApiService.put(`/employee/Policies/${policy.policyId}`, policy)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
		});
	},
	uploadDeclaration(declaration, declarationId) {
		const config = {
			headers: {
				'content-Type': 'multipart/form-data'
			}
		};

		const formData = new FormData();
		formData.append('file', declaration.file)

		formData.append('isGenericDocument', declaration.isGenericDocument);

		return new Promise((resolve, reject) => {
			ApiService.put(`/employee/PolicyDocuments/${declarationId}`, formData, config)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
		});
	},
	createDeclaration(declaration) {
		const config = {
			headers: {
				'content-Type': 'multipart/form-data'
			}
		};

		const formData = new FormData();
		formData.append('file', declaration.file)

		formData.append('isGenericDocument', declaration.isGenericDocument);

		return new Promise((resolve, reject) => {
			ApiService.post(`/employee/PolicyDocuments`, formData, config)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
		});
	},	
	createPolicy(policy) {
		policy.locationIds = policy.locations;

		return new Promise((resolve, reject) => {
			ApiService.post('/employee/Policies', policy)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
		});
	}, 
	getChangelog(policyId, pageSize, pageNumber, startDate, endDate) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/employee/changelog/${policyId}?resultCount=${pageSize}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getEmployeeAssessmentSettings() {
		return new Promise((resolve, reject) => {
			ApiService.get('/employee/employeeAssessmentSettings').then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	saveEmployeeAssessmentSettings(settings) {
		return new Promise((resolve, reject) => {
			ApiService.put('/employee/employeeAssessmentSettings', settings)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
		});
	},
	getEmployeeSettingsChangelog(pageSize, pageNumber, startDate, endDate) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/employee/changelog/employeeSettings?resultCount=${pageSize}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getEmployeeAssessmentSettingsOptions() {
		return new Promise((resolve, reject) => {
			ApiService.get('/employee/options/employeeAssessmentSettings').then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getTrainingSettings() {
		return new Promise((resolve, reject) => {
			ApiService.get('/employee/dcpcSettings').then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	saveTrainingSettings(settings) {
		return new Promise((resolve, reject) => {
			ApiService.put('/employee/dcpcSettings', settings).then(
				(response) => {
					resolve(response);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getEmployeeList(pageSize, pageNumber, searchTerm, sortField, sortDir, filters) {

		var filterQuery = "";

		filters.forEach(function (element, index) {
			filterQuery += `filters[${index}].Key=${encodeURIComponent(element.name)}&filters[${index}].Value=${encodeURIComponent(element.value)}&`
		});

		return new Promise((resolve, reject) => {
			ApiService.get(`/employee/Employees?requestCount=${pageSize}&pageNumber=${pageNumber}&searchTerm=${encodeURIComponent(searchTerm)}&sortField=${encodeURI(sortField)}&sortDirection=${sortDir}&${filterQuery}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	internalSearchEmployees(pageSize, pageNumber, searchTerm) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/employee/Search?requestCount=${pageSize}&pageNumber=${pageNumber}&searchTerm=${searchTerm}`)
				.then(function (response) {
					resolve(response.data);
				})
				.catch(function () {
					reject("Unable to search employees. Please try again.");
				});
		});
	},
	searchEmployees(data) {
		return new Promise((resolve, reject) => {
			ApiService.post('/employee/Employees/Search', data).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	bulkMoveEmployeesForLocation(settings) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/employee/BulkMove/Location`, settings)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
		});
	},
	bulkMoveEmployeesForAccount(settings) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/employee/BulkMove/Account`, settings)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
		});
	},
	getBulkMoveChangeLogForLocation(locationId, pageSize, pageNumber, startDate, endDate) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/employee/BulkMove/Location/Changelog/${locationId}?resultCount=${pageSize}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getEmployee(employeeId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/employee/Employees/${employeeId}`)
				.then((response) => resolve(response.data))
				.catch((error) => reject(error));
		})
	},
	crackDriverCard(driverCard) {
		const config = {
			headers: {
				'content-Type': 'multipart/form-data'
			}
		};

		const formData = new FormData();
		formData.append('file', driverCard)

		return new Promise((resolve, reject) => {
			ApiService.post(`/employee/DriverCards/Contents`, formData, config)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
		});
	},
	GetHoldingAreaCounts() {
		return ApiRequestService.get('employee', 'holdingArea');
	},
	uploadPrintout(formFile, printoutForDate, employeeId) {
		const config = {
			headers: {
				'content-Type': 'multipart/form-data'
			}
		};

		const formData = new FormData();
		formData.append('formFile', formFile)
		formData.append('printoutForDate', printoutForDate);
		formData.append('employeeId', employeeId);

		return new Promise((resolve, reject) => {
			ApiService.post(`/employee/Printouts/`, formData, config)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
		});
	},
	postAwaitingData(employeeId, date, type) {
		let payload = {
			employeeId: employeeId,
			date: date,
			type: {
				id: type
			}
		}
		return new Promise((resolve, reject) => {
			ApiService.post(`/employee/AwaitingData/`, payload)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
		});
	}
}