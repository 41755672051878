import React, { Fragment } from "react";
import { UserAccess } from "components/user-access";
import { Breadcrumbs, VerticalLinkGroup } from "components/navigation";
import { Redirect, Route, Switch } from "react-router-dom";
import { PageRestricted } from "pages/errors/page-restricted";
import { LicenceCheckingUsers } from "pages/licence-checking/settings/users/LicenceCheckingUsers";
import { LicenceCheckingGeneral } from "pages/licence-checking/settings/general/LicenceCheckingGeneral";
import { LicenceCheckingAlerts } from "pages/licence-checking/settings/alerts/LicenceCheckingAlerts";
import { LicenceCheckingHistory } from "pages/licence-checking/settings/history/LicenceCheckingHistory";
import { LicenceCheckingCreditHistory } from "pages/licence-checking/settings/credit-history/LicenceCheckingCreditHistory";

export function LicenceCheckingSettings(props) {
  let routes = [
    { location: "/licence-checking/settings/user-access", title: "Users" },
    { location: "/licence-checking/settings/general", title: "Settings" },
    { location: "/licence-checking/settings/alerts", title: "Alerts" },
    { location: "/licence-checking/settings/history", title: "History" },
    {
      location: "/licence-checking/settings/credit-history",
      title: "Credit History",
    },
  ];

  return (
    <UserAccess
      perform={["LicenceChecking:Manage"]}
      yes={() => (
        <Fragment>
          <div className="row">
            <Breadcrumbs items={props.breadcrumbs} />

            <h1>Manage Licence Checking</h1>
          </div>

          <div className="layout-cols">
            <div className="col side-col">
              <div className="row">
                <VerticalLinkGroup
                  links={routes}
                  exactRouteMatch={false}
                ></VerticalLinkGroup>
              </div>
            </div>
            <div className="col content-col">
              <Switch>
                <Route path="/licence-checking/settings/user-access">
                  <LicenceCheckingUsers />
                </Route>

                <Route path="/licence-checking/settings/general">
                  <LicenceCheckingGeneral />
                </Route>

                <Route path="/licence-checking/settings/alerts">
                  <LicenceCheckingAlerts />
                </Route>

                <Route path="/licence-checking/settings/history">
                  <LicenceCheckingHistory />
                </Route>

                <Route path="/licence-checking/settings/credit-history">
                  <LicenceCheckingCreditHistory />
                </Route>

                <Route
                  exact
                  path="/licence-checking/settings"
                  render={() => (
                    <Redirect to="/licence-checking/settings/user-access" />
                  )}
                />
              </Switch>
            </div>
          </div>
        </Fragment>
      )}
      no={() => <PageRestricted />}
    />
  );
}
