import React, { Fragment } from "react";
import {
  AuthenticatedRoute,
  UserAccess,
  UserHasAccess,
} from "components/user-access";
import { PageRestricted } from "pages/errors/page-restricted";
import { Breadcrumbs, HorizontalLinkGroup } from "components/navigation";
import { Redirect, Switch, useHistory } from "react-router-dom";
import { LicenceCheckingOverview } from "pages/licence-checking/overview";
import { LicenceCheckingDriverOnboarding } from "pages/licence-checking/driver-onboarding";
import { LicenceCheckingPolicies } from "pages/licence-checking/policies";
import { LicenceCheckingCompletedChecks } from "pages/licence-checking/completed-checks";
import { LicenceCheckingCredits } from "pages/licence-checking/credits";
import { Button } from "components/buttons";
import { CreditCount } from "pages/licence-checking/shared/credit-count/CreditCount";

export function LicenceChecking(props) {
  const history = useHistory();
  const licenceCheckingView = "LicenceChecking:View";
  const licenceCheckingManage = "LicenceChecking:Manage";
  const licenceCheckingPurchaseCredits = "LicenceChecking:PurchaseCredits";

  const routes = [
    { location: "/licence-checking/overview", title: "Overview" },
    {
      location: "/licence-checking/driver-onboarding",
      title: "Driver Onboarding",
    },
    {
      location: "/licence-checking/completed-checks",
      title: "Completed Checks",
    },
  ];

  if (UserHasAccess(licenceCheckingManage)) {
    routes.push({ location: "/licence-checking/policies", title: "Policies" });
    routes.push({ location: "/licence-checking/credits", title: "Credits" });
  }

  function redirectToLicenceCheckingSettings() {
    history.push("/licence-checking/settings");
  }

  return (
    <UserAccess
      perform={licenceCheckingView}
      yes={() => (
        <Fragment>
          <div className="row">
            <Breadcrumbs items={props.breadcrumbs} />

            <h1>Licence Checking Hub</h1>

            <div className="button-row button-row-stacked-mobile spacer-bottom">
              <div className="inline-items inline-items-space-reverse space-large spacer-bottom-large">
                <div>
                  <CreditCount />
                  <UserAccess
                    perform={licenceCheckingPurchaseCredits}
                    yes={() => <Button text="Buy Credits" intent="primary" />}
                  />
                </div>
                <div>
                  <UserAccess
                    perform={licenceCheckingManage}
                    yes={() => (
                      <Button
                        text="manage licence checking"
                        intent="primary"
                        onClick={redirectToLicenceCheckingSettings}
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="spacer-bottom">
              <HorizontalLinkGroup
                links={routes}
                exactRouteMatch={false}
              ></HorizontalLinkGroup>
            </div>

            <Switch>
              <AuthenticatedRoute path="/licence-checking/overview">
                <LicenceCheckingOverview />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/licence-checking/driver-onboarding">
                <LicenceCheckingDriverOnboarding />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/licence-checking/completed-checks">
                <LicenceCheckingCompletedChecks />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/licence-checking/policies">
                <LicenceCheckingPolicies />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/licence-checking/credits">
                <LicenceCheckingCredits />
              </AuthenticatedRoute>
              <Redirect
                exact
                from="/licence-checking"
                to="/licence-checking/overview"
              />
            </Switch>
          </div>
        </Fragment>
      )}
      no={() => <PageRestricted />}
    />
  );
}
