import { Intent, Tooltip, Button } from '@blueprintjs/core';
import { DateToLocal } from 'components/dates';
import { ListingTable } from 'components/listing';
import { NotificationToaster } from 'components/notifications';
import { StatusBlock } from 'components/status';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { AlertService } from 'services/AlertService';
import { LicenceBureauService } from "services/LicenceBureauService";

export function EmployeeLicenceCheckingAlerts(props) {
    const defaultRecordSize = 25;
    const headers = [
        "Severity",
        "Type",
        "Information",
        "Due date"
    ];

    const filters = [
        {
            key: 'Status',
            value: 'Active'
        }
    ];

    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(defaultRecordSize);
    const [loading, setLoading] = useState(true);
    const [tableRows, setTableRows] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(loadAlerts, [props.employeeId, pageNumber, pageSize, props.licenceBureauAlertTypes]);

    function loadAlerts() {
        if (props.licenceBureauAlertTypes.length > 0 && props.employeeId) {
            const allFilters = [
                ...filters,
                {
                    key: 'EntityId',
                    value: props.employeeId
                }
            ];

            const alertRequest = {
                requestCount: pageSize,
                pageNumber: pageNumber,
                searchTerm: '',
                sortField: 'alertData.title',
                alertTypeIds: props.licenceBureauAlertTypes.map(x => x.id),
                filters: allFilters
            };

            AlertService.getAlerts(alertRequest).then(r => {
                let data = [];
                if (r?.data != null) {
                    data = r.data.map((d) => {
                        return [formatSeverity(d), 
                            d.alertData.title, 
                            d.alertData.description, 
                            <DateToLocal format='DD/MM/YYYY HH:mm'>{d.alertData.dueDate}</DateToLocal>, 
                            <Tooltip content="View in Licence Bureau">
                                <Button 
                                    icon={"link"} 
                                    minimal={true} 
                                    iconOnly={true} 
                                    large={false} 
                                    onClick={() => navigateToLicenceBureau(d.alertData.organisationId, d.externalKey)} 
                                />
                            </Tooltip>];
                    });
                }

                setTableRows(data);
                setTotalRecords(r.totalCount);
                setLoading(false);
            }, () => NotificationToaster.show(Intent.DANGER, "Could not load alerts. Please try again."));
        }
    }

    function onPagingChange(newPageNumber, newPageSize) {
        setPageNumber(newPageNumber);
        setPageSize(newPageSize);
        setLoading(true);
    }

    function formatSeverity(alert) {
        const alertType = props.licenceBureauAlertTypes.find(x => x.id === alert.alertTypeId);

        if (alertType.key === 'licence-bureau-critical') {
            return <StatusBlock background='EC2D6D' large={false} bold={false}>Critical</StatusBlock>;
        } else if (alertType.key === 'licence-bureau-high') {
            return <StatusBlock background='FF7454' large={false} bold={false}>High</StatusBlock>;
        }
        return <StatusBlock background='FFC107' large={false} bold={false}>Medium/Low</StatusBlock>;
    }

    function navigateToLicenceBureau(orgId, taskId) {
        LicenceBureauService.navigateToLicenceBureau(orgId, 
            taskId, 
            () => NotificationToaster.show(Intent.DANGER, "Unable to load the link to the licence checking provider. Please try again."))
    }
    return <div className='spacer-top-small'>
        <ListingTable
            id="listing-table-licence-check-alerts"
            headers={headers}
            data={tableRows}
            totalRecordCount={totalRecords}
            onPagingChange={onPagingChange}
            loadingData={loading}
            noDataMessage={'There are no licence bureau alerts for the employee.'}
        />
    </div>;
}

EmployeeLicenceCheckingAlerts.defaultProps = {
    employeeId: null,
    licenceBureauAlertTypes: []
};

EmployeeLicenceCheckingAlerts.propTypes = {
    employeeId: PropTypes.string,
    licenceBureauAlertTypes: PropTypes.array
};