import React, { useEffect, useState } from "react";
import { FormNumericInput, FormSwitch, FormSwitchList } from "components/form-fields";
import { UserAccess } from "components/user-access";
import { ButtonSave } from "components/buttons";
import { LicenceCheckingService } from "services";
import { Intent } from "@blueprintjs/core";
import { AlertUnsavedData, NotificationToaster } from "components/notifications";
import { useDirtyData } from "hooks/useDirtyData";
import axios from "axios";

export function LicenceCheckingGeneral() {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [rawLicenceCheckingSettings, setRawLicenceCheckingSettings] = useState({});
  const [licenceCheckingSettings, setLicenceCheckingSettings] = useState({
    autoCheckOnEnrollment: false,
    autoCheckOnPhotocardExpiry: false,
    autoReconsentWeeks: 0,
    autoCheckEntitlements: [],
  });
  const isDataDirty = useDirtyData(rawLicenceCheckingSettings, licenceCheckingSettings);
  
  useEffect(initialLoad, []);

  function initialLoad() {
    axios
      .all([
        LicenceCheckingService.getLicenceCheckingOptions(),
        LicenceCheckingService.getLicenceCheckingSettings(),
      ])
      .then(
        axios.spread((options, settings) => {
          let mappedSettings = mapSettings(settings, options);
          setLicenceCheckingSettings(mappedSettings);
          setRawLicenceCheckingSettings(mappedSettings);
        }),
        () => {
          showNotification(
            Intent.DANGER,
            "Could not load settings. Please try again."
          );
        }
      )
      .catch(() => {
        showNotification(
          Intent.DANGER,
          "Could not load settings. Please try again."
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function showNotification(type, message) {
    NotificationToaster.show(type, message);
  }

  function mapSettings(settings, options) {
    return {
      autoCheckOnEnrollment: settings.autoCheckOnEnrollment,
      autoCheckOnPhotocardExpiry: settings.autoCheckOnPhotocardExpiry,
      autoReconsentWeeks: settings.autoReconsentWeeks,
      autoCheckEntitlements: options.entitlements.map((entitlement) => {
        return {
          id: entitlement.id,
          name: entitlement.code,
          checked: settings.autoCheckEntitlements.includes(entitlement.id),
        };
      }),
    };
  }

  function checkNewDriversChange(event) {
    setLicenceCheckingSettings((prev) => ({
      ...prev,
      autoCheckOnEnrollment: event.target.checked,
    }));
  }

  function checkPhotoCardExpiryDateChange(event) {
    setLicenceCheckingSettings((prev) => ({
      ...prev,
      autoCheckOnPhotocardExpiry: event.target.checked,
    }));
  }

  function onReconsentWeeksChance(value) {
    if (!Number.isInteger(value)) {
      //For some reason the control tries to set the value to an empty string if it's null
      return;
    }

    setLicenceCheckingSettings((prev) => ({
      ...prev,
      autoReconsentWeeks: value,
    }));
  }

  function onEntitlementsChange(event) {
    const toggleId = parseInt(event.currentTarget.getAttribute("identifier"));
    const checked = event.target.checked;

    setLicenceCheckingSettings((prev) => ({
      ...prev,
      autoCheckEntitlements: licenceCheckingSettings.autoCheckEntitlements.map(
        (entitlement) =>
          entitlement.id === toggleId
            ? { ...entitlement, checked: checked }
            : entitlement
      ),
    }));
  }

  function saveChanges() {
    setIsSaving(true);

    LicenceCheckingService.saveLicenceCheckingSettings({
      ...licenceCheckingSettings,
      autoCheckEntitlements: licenceCheckingSettings.autoCheckEntitlements
        .filter((entitlement) => entitlement.checked)
        .map((entitlement) => entitlement.id),
    })
      .then(
        () => {
          showNotification(
            Intent.SUCCESS,
            "Settings saved successfully."
          );
        },
        () => {
          showNotification(
            Intent.DANGER,
            "Could not save settings. Please try"
          );
        }
      )
      .catch(() => {
        showNotification(
          Intent.DANGER,
          "Could not save settings. Please try again."
        );
      })
      .finally(() => {
        setIsSaving(false);
      });
  }

  return (
    <UserAccess
      perform={"LicenceChecking:Manage"}
      yes={() => (
        <div className="row">
          <h2>Settings</h2>

          <h3>New Drivers</h3>

          <AlertUnsavedData
            isDirty={isDataDirty}
            scrollToBottomOnCancel={true}
          ></AlertUnsavedData>

          <FormSwitch
            disabled={isSaving}
            loading={isLoading}
            headingText="Automatically check new drivers"
            checked={licenceCheckingSettings.autoCheckOnEnrollment}
            onChange={checkNewDriversChange}
            inline={false}
          />

          <FormSwitch
            disabled={isSaving}
            loading={isLoading}
            headingText="Automatically check on photo card expiry date"
            checked={licenceCheckingSettings.autoCheckOnPhotocardExpiry}
            onChange={checkPhotoCardExpiryDateChange}
            inline={false}
          />

          <FormNumericInput
            id="reconsentWeeks"
            disabled={isSaving}
            headingText="Number of weeks for auto reconsent"
            onValueChange={onReconsentWeeksChance}
            selectedValue={licenceCheckingSettings.autoReconsentWeeks}
            loading={isLoading}
            min={1}
            max={52}
          />

          <FormSwitchList
            items={licenceCheckingSettings.autoCheckEntitlements}
            onChange={onEntitlementsChange}
            loading={isLoading}
            switchListName="featureList"
            disabled={isSaving}
            headingLevel="h4"
            headingText="Automatically check on entitlement expiry date"
          />

          <ButtonSave
            onClick={saveChanges}
            disabled={isSaving}
            loading={isLoading}
          ></ButtonSave>
        </div>
      )}
    />
  );
}
