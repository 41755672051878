import React from "react";

export function CreditCount() {
  return (
    <div className="inline-item text-align-right spacer-top-small">
      <h6 className="no-margin">LICENCE CHECKING CREDIT</h6>
      <p>1000</p>
    </div>
  );
}
