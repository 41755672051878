import ApiService from 'services/ApiService';
export const LicenceBureauService = {
	getLicenceCheck(employeeId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/employee/LicenceCheck/LicenceBureau/${employeeId}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getUser(organisationId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/user/LicenceCheck/LicenceBureau/${organisationId}/user`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	navigateToLicenceBureau(orgId, taskId, onError) {
        this.getUser(orgId)
        .then((user) => {
            let url = `${user.signInUrl}/tasks/${taskId}`
            window.open(url);

        }, () => {
            onError();
        });
    }
}