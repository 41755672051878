import ApiService from "services/ApiService";

export const LicenceCheckingService = {
  getLicenceCheckingOptions() {
    return new Promise((resolve, reject) => {
      ApiService.get(`/employee/Options/LicenceChecking`).then(
        (result) => {
          resolve(result.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  getLicenceCheckingSettings() {
    return new Promise((resolve, reject) => {
      ApiService.get(`/employee/LicenceCheckingSettings`).then(
        (result) => {
          resolve(result.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  saveLicenceCheckingSettings(settings) {
    return new Promise((resolve, reject) => {
      ApiService.put("/employee/LicenceCheckingSettings", settings)
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
};
