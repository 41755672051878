import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useDebounce } from 'hooks/useDebounce';
import { FormDateRangeInput, FormLocationSelector, FormTextInput, FormTextArea, FormRadio, FormDateInput } from 'components/form-fields';
import { Button } from 'components/buttons'
import { ListingTable } from "components/listing";
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { JobService, LocationService } from 'services';
import { ShowHide } from "components/layout";
import moment from "moment";
import { Tooltip } from 'components/tooltip';
import { useSelector, useDispatch } from 'react-redux';
import { SetFiltering } from 'state/actions';
import { DateToLocal } from "components/dates";
import { AlertConfirm, NotificationToaster, NotificationInline } from "components/notifications";
import { useValidation } from "hooks/useValidation";
import { Intent } from '@blueprintjs/core';
import { StatusBlock } from "components/status";
import './VisitsListing.css';
import axios from 'axios';
export function VisitsListing(props) {

    const defaultRecordSize = 25;
    const debounceTimeout = 750;

    const [totalRecords, setTotalRecords] = useState(0);
    const [tableRows, setTableRows] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(defaultRecordSize);
    const [loadingData, setLoadingData] = useState(true);
    const [savingData, setSavingData] = useState(false);
    const [locationSetUp, setLocationSetUp] = useState(false);
    const [dateSetUp, setDateSetUp] = useState(false);
    const [tableSortBy, setTableSortBy] = useState({ headerName: "Date", sortName: "COMPLETEDSCHEDULEDUEDDATE" });
    const [tableSortDir, setTableSortDir] = useState("D");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [showAcknowledge, setShowAcknowledge] = useState(false);
    const [showAmend, setShowAmend] = useState(false);
    const [currentJobId, setCurrentJobId] = useState(-1);
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);
    const [isValid, errors, validate] = useValidation();
    const [acknowledgedOnBehalfOf, setAcknowledgedOnBehalfOf] = useState({
        acknowledgedOnBehalfOf: ""
    });
    const [amendmentDetails, setAmendmentDetails] = useState({
        reschedule: true,
        scheduleDate: null,
        phoneNumber: "",
        reasonForChange: ""
    });
    const [tableHeaders, setTableHeaders] = useState([]);
    const [locationList, setLocationList] = useState({ location: [], hierarchy: [] });
    const [actionVerb, setActionVerb] = useState("amend");
    const [actionNoun, setActionNoun] = useState("amendment");
    const [productName, setProductName] = useState("");
    const [location, setLocation] = useState("");
    const acknowledgedOnBehalfOfInputRef = useRef(null);
    const amendmentInputRef = useRef(null);
    const searchInputRef = useRef(null);
    const canAccessPage = UserHasAccess(props.requiredAction);
    const isInternal = UserHasAccess("Internal");
    const noDataMessage = "No visits found.";
    const tableHeadersUpcomingJobs = ["Reference", "Scheduled For", "Product Name", "Scheduled status", "Asset Id", "Asset Type", "Visit Location", "Duration", "Status", "Actions"];
    const sortableHeaders = [{ headerName: "Reference", sortName: "JOBID" },
    { headerName: "Product Description", sortName: "PRODUCTDESCRIPTION" },
    { headerName: "Product Name", sortName: "PRODUCTNAME" },
    { headerName: "Visit Location", sortName: "LOCATIONNAME" },
    { headerName: "Comp/Sch Date", sortName: "COMPLETEDSCHEDULEDDATE" },
    { headerName: "Scheduled For", sortName: "COMPLETEDSCHEDULEDDATE" },
    { headerName: "Internal Id", sortName: "PUBSJOBID" },
    { headerName: "Status", sortName: "STATE" },
    { headerName: "Due Date", sortName: "DUEDATE" }];
    const newState = "New";
    const waitingState = "Waiting";
    const pendingState = "Pending";
    const readyState = "Ready";
    const inProgressState = "In Progress";
    const visBA = "VIS";
    const tachoBA = "Tacho";
    const auditBA = "Audit";
    const dateFilterReduxName = "jobListingDates";
    const jobLocationReduxName = "jobListingLocation";
    const jobSearchReduxName = "jobSearch";
    const rescheduleMaxDateDaysInFuture = 500;
    const thirtyMinutes = 30;

    const reduxDates = useSelector(state => state.filters[dateFilterReduxName]);
    const reduxLocations = useSelector(state => state.filters[jobLocationReduxName]);
    const reduxSearch = useSelector(state => state.filters[jobSearchReduxName]);
    const dispatch = useDispatch();

    const [selectedLocations, setSelectedLocations] = useState([]);
    const locationListOnFilterOpen = useRef(selectedLocations);
    const currentPageNumber = useRef();
    const currentPageSize = useRef();
    const currentSearchTerm = useRef();
    const currentTableSortBy = useRef();
    const currentTableSortDir = useRef();
    const currentFilters = useRef();
    const currentStartDate = useRef();
    const currentEndDate = useRef();

    useEffect(() => {
        setUpPage();
    }, []);

    useEffect(() => {
        validateAcknowledgedOnBehalfOf();
    }, [acknowledgedOnBehalfOf]);

    useEffect(() => {
        validateAmendmentDetails();
    }, [amendmentDetails]);



    useEffect(() => {
        //This will trigger if the data changes, but will only process if the loadingData is set to true.
        if (loadingData && canAccessPage &&
            ((startDate && endDate) || (!startDate && !endDate)) &&
            locationSetUp && dateSetUp && selectedLocations != null) {

            let filtersArray = getFilters();

            if (
                currentPageNumber.current === pageNumber &&
                currentPageSize.current === pageSize &&
                currentSearchTerm.current === searchTerm &&
                currentTableSortBy.current === tableSortBy &&
                currentTableSortDir.current === tableSortDir &&
                currentFilters.current === filtersArray.length &&
                currentStartDate.current === startDate &&
                currentEndDate.current === endDate
            ) {
                //If none of the values have changed, then 2 have been updated at once, so prevent the listing from performing another call.
                return;
            }

            currentPageNumber.current = pageNumber;
            currentPageSize.current = pageSize;
            currentSearchTerm.current = searchTerm;
            currentTableSortBy.current = tableSortBy;
            currentTableSortDir.current = tableSortDir;
            currentFilters.current = filtersArray.length;
            currentStartDate.current = startDate;
            currentEndDate.current = endDate;

            const jobsRequest = {
                requestCount: pageSize,
                pageNumber: pageNumber,
                sortField: tableSortBy.sortName,
                sortDirection: tableSortDir,
                filters: filtersArray
            };

            const loadDataPromises = [];

            var getJobsResponse = null;

            const getJobs = JobService.getJobs(jobsRequest)
                .then(response => getJobsResponse = response);
            loadDataPromises.push(getJobs);
                        
            axios.all(loadDataPromises).then(function () {
                let data = [];

                if (getJobsResponse?.data != null) {
                    data = mapJobData(data, getJobsResponse);
                }

                setTableRows(data);
                setTotalRecords(getJobsResponse.totalCount);
                setLoadingData(false);
                focusOnTextBox(searchInputRef);
            }, function () {
                NotificationToaster.show(Intent.DANGER, "Visits could not be loaded. Please try loading the page again.");
                setLoadingData(false);
            });
        }

    }, [loadingData, pageSize, pageNumber, tableSortBy, tableSortDir, selectedLocations]);

    useEffect(() => {
        if (debouncedSearchTerm !== null && debouncedSearchTerm !== currentSearchTerm.current) {
            setPageNumber(1);
            setLoadingData(true);
        }
    }, [debouncedSearchTerm]);

    useEffect(() => {
        if (!loadingData) {
            dispatch(SetFiltering(jobLocationReduxName,
                {
                    list: selectedLocations
                }
            ));
        }
    }, [selectedLocations]);

    useEffect(() => {
        //We know the redux store has loaded now
        setDates();
    }, [reduxDates]);

    useEffect(() => {
        //We only want to set the locations once, else it gets stuck in a loop
        if (!locationSetUp) {
            setupLocationInfo();
        }
    }, [reduxLocations]);

    useEffect(() => {
        //We know the redux store has loaded now
        if (reduxSearch != null && reduxSearch.length > 0 && reduxSearch !== searchTerm) {
            setSearchTerm(reduxSearch);
        }
    }, [reduxSearch]);

    function mapJobData(data, r) {
        data = r.data.map((d) => {
            let statusBlock = setupStatusBlock(d);
            let duration = '';

            if (d.flsCallDuration != null) {
                let hours = Math.floor(d.flsCallDuration / 60);
                let mins = d.flsCallDuration - (hours * 60);
                duration = hours + " hours " + mins + " mins";
            }
            let scheduledDate = null;
            const isRemote = d.productName.toLowerCase().indexOf('remote') !== -1;
            if (isRemote) {
                scheduledDate = <DateToLocal key={`sch-date-${d.jobId}`}>{d.scheduledDate}</DateToLocal>;
            }
            else {

                var dateUtcMoment = moment.utc(d.scheduledDate);

                if (dateUtcMoment.isValid()) {
                    var dateUtc = dateUtcMoment.toDate();
                    var fromDate = moment(dateUtc).add(-1 * thirtyMinutes, 'minute');
                    var toDate = moment(dateUtc).add(thirtyMinutes, 'minute');
                    scheduledDate = moment(fromDate).local().format("DD/MM/YYYY HH:mm") + ' - ' + moment(toDate).local().format("HH:mm");
                } else {
                    scheduledDate = '-';
                }
            }
            let actionAcknowledge = null;
            //Can only acknowledge jobs with flsScheduledStatus (Fixed or Confirmed at time of writing this comment).
            if (d.flsScheduledStatus && !d.acknowledged && (d.amendmentType == null || d.amendmentType.length < 1)) {
                actionAcknowledge = <Tooltip content='Click to acknowledge visit.'><Button icon='plus' minimal={true} large={false} onClick={() => onAcknowledgeRowClick(d.jobId)}></Button></Tooltip>;
            }

            let actionAmend = null;
            if (d.amendmentType == null || d.amendmentType.length < 1) {
                actionAmend = <Tooltip content='Click to request amendment to visit.'><Button icon='edit' minimal={true} large={false} onClick={() => onAmendRowClick(d.jobId, d.productName, d.locationName)}></Button></Tooltip>;
            }

            return [d.pubsJobId ?? d.jobId, scheduledDate, d.productName, statusBlock,
            d.assetRegistration, d.assetType, d.locationName, duration, d.state,
            <div className="button-row-small inline-items" key={`actions-${d.jobId}`}>
                {actionAmend}{actionAcknowledge}
            </div>
            ];
        });
        return data;
    }

    function resetCurrent() {
        currentPageNumber.current = null;
        currentPageSize.current = null;
        currentSearchTerm.current = null;
        currentTableSortBy.current = null;
        currentTableSortDir.current = null;
        currentFilters.current = null;
        currentStartDate.current = null;
        currentEndDate.current = null;
    }

    function setUpPage() {
        //Just to ensure page loads
        resetCurrent();

        setTableHeaders(tableHeadersUpcomingJobs);        
    }
    function getFilters() {

        let mappedFilters = [];

        let filtersArray = [...mappedFilters,
        { "key": "Search", "value": searchTerm }
        ];

        if (startDate && endDate) {
            filtersArray = [...filtersArray,
            { "key": "CompletedScheduledStartDate", "value": startDate },
            { "key": "CompletedScheduledEndDate", "value": endDate }
            ];
        }

        //Should only be able to see open jobs in this page        
        let filterStateKey = "State";
        let filterBAKey = "BusinessArea";

        filtersArray = [...filtersArray,
            { "key": filterStateKey, "value": newState },
            { "key": filterStateKey, "value": pendingState },
            { "key": filterStateKey, "value": readyState },
            { "key": filterStateKey, "value": inProgressState },
            { "key": filterStateKey, "value": waitingState },
            { "key": filterBAKey, "value": visBA },
            { "key": filterBAKey, "value": tachoBA },
            { "key": filterBAKey, "value": auditBA },
            { "key": "FlsScheduled", "value": "true" }
            ];
        

        if (selectedLocations != null) {
            selectedLocations.forEach(function (l) {
                filtersArray = [...filtersArray,
                { "key": "Location", "value": l }
                ];
            });
        }

        return filtersArray;
    }

    function setupStatusBlock(jobRow) {
        let statusBlockTooltip = null;
        let scheduledStatus = null;
        let scheduledHollow = false;
        let schedueledText = "Scheduled"
        let statusBlock = null;
        if (jobRow.flsScheduledStatus) {
            if (jobRow.amendmentType != null && jobRow.amendmentType.length > 0) {
                if (jobRow.amendmentType === 'Amendment') {
                    statusBlockTooltip = <span>Pending {jobRow.amendmentType.toLowerCase()} to <DateToLocal format='DD/MM/YYYY'>{jobRow.rescheduleDate}</DateToLocal> requested by {jobRow.amendmentBy} on <DateToLocal format='DD/MM/YYYY'>{jobRow.amendmentDate}</DateToLocal>.</span>
                } else {
                    statusBlockTooltip = <span>Pending {jobRow.amendmentType.toLowerCase()} requested by {jobRow.amendmentBy} on <DateToLocal format='DD/MM/YYYY'>{jobRow.amendmentDate}</DateToLocal>.</span>
                }
            } else if (jobRow.acknowledged) {
                statusBlockTooltip = <span>Acknowledged by {jobRow.acknowledgedBy} on <DateToLocal format='DD/MM/YYYY'>{jobRow.acknowledgedDate}</DateToLocal>.</span>
            } else {
                statusBlockTooltip = <span>Awaiting acknowledgement.</span>
            }

            if (jobRow.amendmentType != null) {
                scheduledStatus = jobRow.amendmentType === 'Amendment' ? 'Amber' : 'Red';
                schedueledText = jobRow.amendmentType === 'Amendment' ? 'Amendment' : 'Cancellation';
            } else {
                scheduledHollow = !jobRow.acknowledged;
            }
            statusBlock = <Tooltip content={statusBlockTooltip}>
                <StatusBlock status={scheduledStatus} hollow={scheduledHollow} bold={false} large={false}>{schedueledText}</StatusBlock>
            </Tooltip>;
        }
        return statusBlock;
    }

    function setDates() {
        if (reduxDates && reduxDates.startDate !== null && reduxDates.endDate !== null) {
            setStartDate(reduxDates.startDate?.format("YYYY-MM-DD"));
            setEndDate(reduxDates.endDate?.format("YYYY-MM-DD"));
        } else if (dateSetUp) {
            setStartDate(null);
            setEndDate(null);
        }
        else {
            let defaultdays = 60;
            setStartDate(moment().format("YYYY-MM-DD"));
            setEndDate(moment().add(defaultdays, 'd').format("YYYY-MM-DD"));
        }
        setDateSetUp(true);
        setLoadingData(true);
    }

    function setupLocationInfo() {
        LocationService.getFormLocationSelectorCustomLocations(['Tacho', 'Vis', 'Audit']).then(function (locations) {
            setLocationList(locations);
            setLocationSetUp(true);
            if (reduxLocations != null && reduxLocations.list.length > 0) {
                setSelectedLocations(reduxLocations.list);
            } else {
                setSelectedLocations(locations.location[0].items.map(l => l.id));
            }
        }, function (error) {
            NotificationToaster.show(Intent.DANGER, `Locations failed ${error}`);
        });
        setLoadingData(true);
    }

    function validateAmendmentDetails() {
        let amendRules = [{ fieldName: "reschedule", required: true }];
        let externalRules = [];

        if (amendmentDetails.reasonForChange !== null && amendmentDetails.reasonForChange.length < 10) {
            let requireScheduleData = {
                fieldName: "reasonForChange",
                valid: false,
                errorMessage: 'The ' + actionNoun + ' reason must be at least 10 characters long.'
            };
            externalRules.push(requireScheduleData);
        }

        if (amendmentDetails.reschedule) {
            amendRules.push({ fieldName: "scheduleDate", required: true });
            amendRules.push({ fieldName: "phoneNumber", required: true, type: "phone" });
        }

        validate(amendRules, amendmentDetails, externalRules);
    }

    function validateAcknowledgedOnBehalfOf() {
        let acknowledgedRules = [];
        if (isInternal) {
            acknowledgedRules = [{ fieldName: "acknowledgedOnBehalfOf", required: true }];
        }
        validate(acknowledgedRules, acknowledgedOnBehalfOf);
    }

    function onPagingChange(newPageNumber, newPageSize) {
        setPageNumber(newPageNumber);
        setPageSize(newPageSize);
        setLoadingData(true);
    }

    function onTableSort(header, direction) {
        setTableSortBy(header);
        setTableSortDir(direction);
        setLoadingData(true);
    }
    
    function onDateRangeChange(item) {
        if ((item?.startDate?.date != null && item?.endDate?.date != null) || (item?.startDate?.date == null && item?.endDate?.date == null)) {
            setPageNumber(1);

            //Using the redux store should mean the setDates is called
            dispatch(SetFiltering(dateFilterReduxName, {
                startDate: item.startDate?.date,
                endDate: item.endDate?.date
            }));
        }
    }

    function onLocationListOpened() {
        locationListOnFilterOpen.current = selectedLocations;
    }

    function onLocationListClose() {
        if (locationListOnFilterOpen.current.toString() !== selectedLocations.toString()) {
            setLoadingData(true);
        }
    }

    function onAmendRowClick(jobId, prodName, locationName) {
        setCurrentJobId(jobId);
        setShowAmend(true);
        validateAmendmentDetails();
        focusOnTextBox(amendmentInputRef);
        setProductName(prodName);
        setLocation(locationName);
    }

    function onAmendConfirm() {
        validateAmendmentDetails();
        if (isValid) {
            setSavingData(true);
            JobService.amendJob(currentJobId, amendmentDetails).then(function () {
                resetCurrent();
                closeAmend(true);
                NotificationToaster.show(Intent.SUCCESS, `Visit ${actionVerb}ed successfully`);
            },
                (error) => {
                    closeAmend(false);
                    NotificationToaster.show(Intent.DANGER, error);
                }
            );
        }
    }

    function closeAmend(reload) {
        validate([], {});
        resetActions();
        setAmendmentDetails((prevValue) => {
            return {
                ...prevValue,
                reschedule: true,
                scheduleDate: null,
                phoneNumber: "",
                reasonForChange: ""
            }
        });

        setSavingData(false);
        if (reload) {
            setLoadingData(true);
        }
        setShowAmend(false);
    }

    function onAmendCancel() {
        closeAmend(false);
    }

    function onAcknowledgeRowClick(jobId) {
        setCurrentJobId(jobId);
        validateAcknowledgedOnBehalfOf();
        setShowAcknowledge(true);
        focusOnTextBox(acknowledgedOnBehalfOfInputRef);
    }

    function focusOnTextBox(InputRef) {
        if (InputRef.current != null) {
            setTimeout(function () {
                InputRef.current.focus();
            });
        }
    }

    function closeAcknowledge(reload) {
        validate([], {});
        if (isInternal) {
            setAcknowledgedOnBehalfOf((prevValue) => {
                return {
                    ...prevValue,
                    acknowledgedOnBehalfOf: null
                }
            });
        }
        setSavingData(false);
        if (reload) {
            setLoadingData(true);
        }
        setShowAcknowledge(false);
    }

    function onAcknowledgeConfirm() {
        validateAcknowledgedOnBehalfOf();
        if (isValid) {
            setSavingData(true);
            JobService.acknowledgeJob(currentJobId, isInternal ? acknowledgedOnBehalfOf : { acknowledgedOnBehalfOf: null }).then(function () {
                resetCurrent();
                closeAcknowledge(true);
                NotificationToaster.show(Intent.SUCCESS, "Visit acknowledged successfully");
            },
                (error) => {
                    closeAcknowledge(false);
                    NotificationToaster.show(Intent.DANGER, error);
                }
            );
        }
    }

    function onAcknowledgeCancel() {
        closeAcknowledge(false);
    }

    function onAcknowledgedOnBehalfOfChange(item) {
        setAcknowledgedOnBehalfOf((prevValue) => {
            return {
                ...prevValue,
                acknowledgedOnBehalfOf: item.target.value
            }
        });
    }

    function onReasonForChangeChange(item) {
        setAmendmentDetails((prevValue) => {
            return {
                ...prevValue,
                reasonForChange: item.target.value
            }
        });
    }
    function resetActions() {
        setActionVerb("amend");
        setActionNoun("amendment");
    }


    function onRescheduleChange(item) {
        var value = item.target.value === "true";
        if (value) {
            resetActions();
        }
        else {
            setActionVerb("cancel");
            setActionNoun("cancellation");
        }
        setAmendmentDetails((prevValue) => {
            return {
                ...prevValue,
                reschedule: value,
                scheduleDate: null,
                phoneNumber: ""
            }
        });
    }

    function onScheduleDateChange(item) {
        setAmendmentDetails((prevValue) => {
            return {
                ...prevValue,
                scheduleDate: item ? moment(item).format("YYYY-MM-DD") : null
            }
        });
    }

    function onPhoneNumberChange(item) {
        setAmendmentDetails((prevValue) => {
            return {
                ...prevValue,
                phoneNumber: item.target.value
            }
        });
    }

    function onSearchChange(item) {
        setSearchTerm(item.target.value);
        dispatch(SetFiltering(jobSearchReduxName, item.target.value));

    }

    return (
        <UserAccess perform={props.requiredAction}
            yes={() => (
                <div className="row">

                    <div className="inline-items spacer-bottom">
                        <h1>{props.title}</h1>
                    </div>
                    
                    <div className="pull-left spacer-bottom">
                        <div className="inline-items">
                            <FormTextInput
                                maxLength={50}
                                inputRef={searchInputRef}
                                placeholder="Product or Reference"
                                onChange={onSearchChange}
                                value={searchTerm}
                                large
                                disabled={loadingData}
                                icon="search"
                                id="job-search-field" />

                            <FormLocationSelector
                                businessArea={""}
                                loading={loadingData}
                                selectedLocations={selectedLocations}
                                setSelectedLocations={setSelectedLocations}
                                useHierarchy={false}
                                useLocationDefaults={false}
                                locationDefaultsToTrue={true}
                                onLocationListClose={onLocationListClose}
                                onLocationListOpened={onLocationListOpened}
                                customLocations={locationList}
                                useCustomLocations={true}
                            />
                            <FormDateRangeInput
                                onChange={onDateRangeChange}
                                startDate={startDate}
                                endDate={endDate}
                                disabled={loadingData}
                                headingText={""}
                                large
                            ></FormDateRangeInput>
                                                        
                        </div>
                    </div>
                    
                    <ListingTable
                        id="listing-table-location"
                        headers={tableHeaders}
                        data={tableRows}
                        totalRecordCount={totalRecords}
                        onPagingChange={onPagingChange}
                        loadingData={loadingData}
                        noDataMessage={noDataMessage}
                        sortable
                        sortableHeaders={sortableHeaders}
                        sortedBy={tableSortBy}
                        sortedDir={tableSortDir}
                        onSort={onTableSort}
                    />

                    <AlertConfirm
                        title="Please confirm acknowledgement"
                        isOpen={showAcknowledge}
                        saving={loadingData || savingData}
                        onConfirm={onAcknowledgeConfirm}
                        onCancel={onAcknowledgeCancel}
                        disabled={!isValid}
                    >
                        <ShowHide
                            evaluator={!isInternal}
                            hide={(
                                <Fragment>
                                    <FormTextInput
                                        id="acknowledged-on-behalf-of"
                                        headingText="Acknowledged on behalf of:"
                                        placeholder="Employee name"
                                        value={acknowledgedOnBehalfOf.acknowledgedOnBehalfOf}
                                        large
                                        maxLength={255}
                                        onChange={onAcknowledgedOnBehalfOfChange}
                                        dangerHelperText={errors.acknowledgedOnBehalfOf}
                                        inputRef={acknowledgedOnBehalfOfInputRef}
                                    />
                                </Fragment>
                            )}
                        />
                        <p>Are you sure you want to acknowledge this visit, it will no longer appear on the daily email reminder?</p>
                    </AlertConfirm>

                    <AlertConfirm
                        title={"Please confirm " + actionNoun}
                        isOpen={showAmend}
                        saving={loadingData || savingData}
                        onConfirm={onAmendConfirm}
                        onCancel={onAmendCancel}
                        disabled={!isValid}
                    >
                        <div className="clearfix">
                            <div className="pull-left">
                                <NotificationInline
                                    id="amendment-warning"
                                    text="If you are within 2 working days of the appointment time full cost will be charged. 
                                            Please check your inspection type requirements and ensure that by cancelling this appointment, your compliance schedule is not compromised. 
                                            If you still wish to cancel this appointment please fill in the details below."
                                    intent="warning"
                                    allowClose={false}
                                    show={true}
                                >
                                </NotificationInline>
                                <div>
                                    <FormTextArea
                                        id="reason-for-amendment"
                                        headingText={"Reason for " + actionNoun + ":"}
                                        placeholder="Description"
                                        value={amendmentDetails.reasonForChange}
                                        large
                                        maxLength={1000}
                                        onChange={onReasonForChangeChange}
                                        dangerHelperText={errors.reasonForChange}
                                        inputRef={amendmentInputRef}
                                    />
                                    <FormRadio
                                        onChange={onRescheduleChange}
                                        headingText="Reschedule or cancel visit:"
                                        options={[{ value: true, label: "Reschedule" }, { value: false, label: "Cancel" }]}
                                        selectedValue={amendmentDetails.reschedule}
                                        id="reschedule-cancel-job"
                                    ></FormRadio>

                                    <ShowHide
                                        evaluator={!amendmentDetails.reschedule}
                                        hide={(
                                            <Fragment>
                                                <FormDateInput
                                                    headingText="Please select a prefered date. You will be contacted shortly to confirm availability or discuss alternative appointments:"
                                                    onChange={onScheduleDateChange}
                                                    value={amendmentDetails.scheduleDate}
                                                    minDate={moment().toDate()}
                                                    maxDate={moment().add(rescheduleMaxDateDaysInFuture, 'd').toDate()}
                                                    id="input-tacho-start-date"
                                                    dangerHelperText={errors.scheduleDate}
                                                    disabled={false}
                                                    loading={loadingData}
                                                ></FormDateInput>
                                                <FormTextInput
                                                    id="amend-phone-number"
                                                    headingText="Contact phone number:"
                                                    placeholder="Phone number"
                                                    value={amendmentDetails.phoneNumber}
                                                    large
                                                    maxLength={50}
                                                    onChange={onPhoneNumberChange}
                                                    dangerHelperText={errors.phoneNumber}
                                                    disabled={loadingData}
                                                />
                                            </Fragment>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>

                        <p>Are you sure you want to {actionVerb} this {productName} visit for {location} visit location?</p>
                    </AlertConfirm>
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}